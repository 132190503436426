<template>
<div class="view-home">

  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb pl-0">
        <li class="breadcrumb-item"><a href="#">{{ $t('breadcrumb.main-page') }}</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{ $t('breadcrumb.about') }}</li>
      </ol>
    </nav>
    <div class="page-title text-lg-left text-center">
      {{ $t('about-page.title') }}
    </div>
  </div>

  <div class="container-fluid about-page-item">
    <div class="row">
      <div class="col-lg-12">

        <div class="row about-page-item">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 text-center">
                <img width="200" height="200" src="/images/main5.png" alt="">
              </div>
              <div class="col-lg-8 mt-3 mt-lg-0">
                <div class="title">{{ $t('about-page.card-1-title') }}</div>
                <div class="content">
                  <a href="/">{{ $t('about-page.card-1-qabilet-title') }}</a>{{ $t('about-page.card-1-desc') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row about-page-item">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 text-center">
                <img width="200" height="200" src="/images/main6.png" alt="">
              </div>
              <div class="col-lg-8 mt-3 mt-lg-0">
                <div class="title">{{ $t('about-page.card-2-title') }}</div>
                <div class="content">
                  {{ $t('about-page.card-2-desc') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row about-page-item">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 text-center">
                <img width="200" height="200" src="/images/main7.png" alt="">
              </div>
              <div class="col-lg-8 mt-3 mt-lg-0">
                <div class="title">{{ $t('about-page.card-3-title') }}</div>
                <div class="content">
                  {{ $t('about-page.card-3-desc') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
}
</script>

<style>
	
</style>